import React, { useRef, useContext } from 'react';
import { AppContext } from '../context/AppContextProvider';
import Loader from '../components/Loader';

const Layout = ({children}) => {
    const element = useRef(null);
    const { loading } = useContext(AppContext);

    return (
        <div className="Layout" ref={element}>
            {loading ? <Loader />: ''}
            {children}
        </div>
    );
}

export default Layout;