import React, { useContext, useState } from 'react';
import { dataServices } from '../data';
import logoWithBg from '../Assets/icons/LogoConFondo.svg';
import '../styles/Services.css';
import Header from '../components/Header';
import Transition from '../components/Transition';
import { AppContext } from '../context/AppContextProvider';

const Servicios = () => {
    const {transition} = useContext(AppContext);
    const [service, setService] = useState(0);
    const [loaded, setLoaded] = useState(false);
    
    const handlePrev = () => {
        setLoaded(false);
        setTimeout(() => {
            service === 0 
            ? setService(dataServices.length - 1)
            : setService(service - 1);
            setLoaded(true);
        }, 1000)
      }
    const handleNext = () => {
        setLoaded(false);
        setTimeout(() => {
            service === (dataServices.length - 1) 
            ? setService(0)
            : setService(service + 1);
            setLoaded(true);
        }, 500)
      }
    /* console.table(dataServices); */
    return (
        <React.Fragment>
            <Header menuColor={"#a5d548"} textColor={"white"} btnColor={true} />
            {/* {transition ? <Transition background={"#a5d548"}/> : ''} */}
            <div className="services">
                <div className="service-container">
                    <div className={`service-card ${(loaded /* && !transition */) && 'card-loaded'}`} onLoad={()=> setLoaded(true)}>
                        <h1 className="service-title">
                            {dataServices[service].title}
                        </h1>
                        <p className="service-description">
                            {dataServices[service].description}
                        </p>
                        <img src={logoWithBg} alt="logo" />
                    </div>
                    <div className="service-card__buttons">
                        <button 
                            className="card-button preview"
                            onClick={handlePrev}
                        >
                            {"<"}
                        </button>
                        <button 
                            className="card-button next"
                            onClick={handleNext}
                        >
                            {">"}
                        </button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Servicios;