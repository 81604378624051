import Email from './Assets/icons/Correo.svg';
import WhatsApp from './Assets/icons/WhatsApp.svg';
import Instagram from './Assets/icons/Instagram.svg';

export const sections = [
    {
        id: "home",
        name: "Inicio",
        path: "/"
    },
    {
        id: "about",
        name: "Sobre Mí",
        path: "/sobre-mi"
    },
    {
        id: "services",
        name: "Servicios",
        path: "/servicios"
    },
    {
        id: "contact",
        name: "Contacto",
        path: "/contacto"
    },
];

export const dataAboutMe = [
    {
        class: "biography",
        classTitle: "biography-title",
        classDescription: "biography-description",
        title: "Biografía",
        description: `Kimberly Jaén 
        Chiricana. 
        Egresada de la Universidad Autónoma de Chiriquí. 
        Lic. Nutrición y dietética. 
        Maestría en Docencia Superior. 
        Diplomado en Nutrición Deportiva. 
        Especialidad en Nutrición Clínica y Hospitalaria.`,
    },
    {
        class: "vision",
        classTitle: "vision-title",
        classDescription: "vision-description",
        title: "Visión",
        description: `Guiar la alimentación a lo largo de la vida.
        Embarazada, madre lactante, alimentación complementaria desde los 6 meses de vida.
        Orientación de suplementos para alcanzar objetivos nutricionales en paciente. 
        Creación de menús saludables para reducir medidas y crear objetivos a corto, mediano y largo plazo.`,
    },
    {
        class: "mision",
        classTitle: "mision-title",
        classDescription: "mision-description",
        title: "Misión",
        description: `Apoyar el rendimiento nutricional en todos los grupos etarios.
        Consejería para prevención de enfermedades crónicas, diabetes, hipertensión, obesidad, sobrepeso.
        Tratamiento de diversas patologías.
        Tratamiento para alto rendimiento deportivo.`,
    }
];

export const dataContact = [
    {
        icon: Email,
        title: "Email",
        animation: "fade-in-bottom-1",
        description: "kjaen08@hotmail.com",
        href: "mailto:"
    },
    {
        icon: WhatsApp,
        title: "WhatsApp",
        animation: "fade-in-bottom-2",
        description: "+507 6164-2198",
        href: "https://api.whatsapp.com/send?phone=50761642198"
    },
    {
        icon: Instagram,
        title: "Instagram",
        animation: "fade-in-bottom-3",
        description: "@kim_nhealth",
        href: "https://www.instagram.com/kim_nhealth/"
    }
];

export const dataServices = [
    {
        title: "Planes nutricionales personalizados todos los grupos etarios: ",
        description: <>
            - Niños. <br/>
            - Adolescentes. <br/>
            - Embarazadas. <br/>
            - Madres lactantes. <br/>
        </>
    },
    {
        title: "Tratamiento con dietoterapia para pacientes: ",
        description: <>
        - Diabéticos.<br/>     
        - Hipertensos.<br/>
        - Sobrepeso.<br/> 
        - Obesidad.<br/> 
        - Ovarios poliquísticos.<br/> 
        - Úlceras.<br/> 
        - Gastritis.<br/> 
        </>
    }
];