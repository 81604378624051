import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import { AppContext } from '../context/AppContextProvider';
import { fadeInLeft } from '../styles/animations';
import { fadeIn } from '../styles/animations';

const Mision = () => {
    const {loading } = useContext(AppContext);

    return (
        <div className="mision">
            {
                !loading && (
                    <React.Fragment>
                        <motion.h1 className={`mision-title`}
                            variants={fadeInLeft}
                            initial="hidden"
                            whileInView="visible"
                            viewport={{once: true, amount: 'some'}}
                        >
                            Misión
                        </motion.h1>
                        <motion.p className={`mision-description`}
                            custom={0.5}
                            variants={fadeIn}
                            initial="hidden"
                            whileInView="visible"
                            viewport={{once: true, amount: 'some'}}
                        >
                            Apoyar el rendimiento nutricional en todos los grupos etarios. <br /> 
                            Consejería para prevención de enfermedades crónicas, diabetes, hipertensión, obesidad, sobrepeso. <br /> 
                            Tratamiento de diversas patologías. <br /> 
                            Tratamiento para alto rendimiento deportivo. 
                        </motion.p>
                    </React.Fragment>
                )
            }
        </div>
    );
}

export default Mision;