import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from '../container/Layout';
import Home from '../pages/Home';
import SobreMi from '../pages/SobreMi';
import Servicios from '../pages/Servicios';
import Contacto from '../pages/Contacto';
import NotFound from '../pages/NotFound';
import { AppContextProvider } from '../context/AppContextProvider';

const App = () => {
    return (
        <AppContextProvider>
            <BrowserRouter>
                <Layout>
                    <Routes>
                        <Route exact path='/' element={<Home />}/>
                        <Route exact path='/sobre-mi' element={<SobreMi />}/>
                        <Route exact path='/servicios' element={<Servicios />}/>
                        <Route exact path='/contacto' element={<Contacto />}/>
                        <Route path='*' element={<NotFound />}/>
                    </Routes>
                </Layout>
            </BrowserRouter>
        </AppContextProvider>
    );
}

export default App;