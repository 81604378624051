import React, { useContext } from 'react';
import Header from '../components/Header';
import Transition from '../components/Transition';
import { AppContext } from '../context/AppContextProvider';
import { dataContact } from '../data';
import '../styles/Contact.css';

const Contacto = () => {
    const { loading, transition } = useContext(AppContext);

    return (
        <React.Fragment>
            <Header menuColor={"#ff2180"} textColor={"white"} btnColor={true}/>
            {/* {transition ?<Transition background={"#ff2180"} /> : ''} */}
            <section className="contact">
                <div className={`contact-container ${(!loading /* && !transition */) && 'fade-in'}`}>
                    {
                        dataContact.map((data, key) => {
                            return(
                                <div className={`${data.title} ${(!loading /* && !transition */) && data.animation}`} key={key}>
                                    <img src={data.icon} alt={`logo ${data.title}`} />
                                    <h1>{data.title}</h1>
                                    <a href={data.href} className="">{data.description}</a>
                                </div>
                            );
                        })
                    }
                </div>
            </section>
        </React.Fragment>
    );
}

export default Contacto;