import React, { useContext } from 'react';
import logoLoader from '../Assets/icons/LogoConFondo.svg';
import { AppContext } from '../context/AppContextProvider';
import '../styles/Loader.css';

const Loader = () => {
    const { loading, setLoading } = useContext(AppContext);

    const onAnimationEnd = () => {
        setLoading(false);
    }
    
    return (
        <div className={`loader-container ${loading ? 'loaderOn' : 'noClick'}`}
            // onLoad={() => {setTimeout(() => { setLoading(false)}, 4000)}}
            onAnimationEnd={onAnimationEnd}
        >
            <span className="loader">
                Kimnhealth
            </span>
            <img src={logoLoader} alt="logo" />
        </div>
    );
}

export default Loader;