import React, {useContext} from 'react';
import { AppContext } from '../context/AppContextProvider';
import logoLoader from '../Assets/icons/LogoConFondo.svg';
import '../styles/Transition.css';
import styled from 'styled-components';

const TransitionPage = styled.div`
    background-color: ${props => props.background};
`;

const Transition = ({background}) => {
    const { transition, setTransition } = useContext(AppContext);

    const onTransitionEnd = () => {
        setTransition(false);
    }
    
    return (
        <TransitionPage className={`transition-container ${transition ? 'transitionOn' : 'noClick'}`}
            background={background}
            onAnimationEnd={onTransitionEnd}
        >
            <img src={logoLoader} alt="logo" />
        </TransitionPage>
    );
}

export default Transition;