import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import { AppContext } from '../context/AppContextProvider';
import { fadeInLeft } from '../styles/animations';
import { fadeIn } from '../styles/animations';

const Biografia = () => {
    const { loading } = useContext(AppContext);
    
    return (
        <div className="biography">
            {
                !loading && (
                    <React.Fragment>
                        <motion.h1 className={`biography-title`}
                            variants={fadeInLeft}
                            initial="hidden"
                            whileInView="visible"
                            viewport={{once: true, amount: 'some'}}
                        >
                            Biografía
                        </motion.h1>
                        <motion.p className={`biography-description`}
                            custom={0.5}
                            variants={fadeIn}
                            initial="hidden"
                            whileInView="visible"
                            viewport={{once: true, amount: 'some'}}
                        >
                            Kimberly Jaén <br /> 
                            Chiricana. <br />
                            Egresada de la Universidad Autónoma de Chiriquí. <br />
                            Lic. Nutrición y dietética. <br />
                            Maestría en Docencia Superior. <br />
                            Diplomado en Nutrición Deportiva. <br />
                            Especialidad en Nutrición Clínica y Hospitalaria.
                        </motion.p>
                    </React.Fragment>
                )
            }
        </div>
    );
}

export default Biografia;