export const fadeIn = {
    hidden: {
        opacity: 0,
    },
    visible: i => ({
        opacity: 1,
        transition: {
            duration: 1,
            delay: i
        }
    })
}

export const fadeInRight = {
    hidden: {
      opacity: 0,
      x: 30,
  },
  visible: i => ({
      opacity: 1,
      x: 0,
      transition: {
          duration: 1,
          delay: i
      }
  })
}

export const fadeInLeft = {
  hidden: {
    opacity: 0,
    x: -30,
},
visible: i => ({
    opacity: 1,
    x: 0,
    transition: {
        duration: 1,
        delay: i
    }
})
}

export const fadeInBottom = {
  hidden: {
    opacity: 0,
    y: 30,
},
visible: i => ({
    opacity: 1,
    y: 0,
    transition: {
        duration: 1,
        delay: i
    }
})
}

export const fadeInTop = {
    hidden: {
      opacity: 0,
      y: -30,
  },
  visible: i => ({
      opacity: 1,
      y: 0,
      transition: {
          duration: 1,
          delay: i
      }
  })
  }