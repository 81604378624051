import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import menuOpen from '../Assets/icons/Menu-open.svg';
import menuClose from '../Assets/icons/Menu-close.svg';
import bgUp from '../Assets/icons/brush-left.svg';
import bgDown from '../Assets/icons/brush-right.svg';
import logoWithBg from '../Assets/icons/LogoConFondo.svg';
import { sections } from '../data';
import {AppContext} from '../context/AppContextProvider';
import '../styles/Header.css';
import styled from 'styled-components';
import { fadeIn } from '../styles/animations';


const Navbar = styled.header`
        position: fixed;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        height: 70px;
        z-index: 9;
        background-color: ${props => props.menuColor};
        @media(min-width: 1024px){
            .sections-list li a {
                cursor: pointer;
                color: ${props => props.textColor};
            }
        }
`;

const Header = ({menuColor, textColor, btnColor}) => {
    const { menu, setMenu, setTransition, loading } = useContext(AppContext);

    const handleMenu = () => { //Apertura y Cierre Menu
        setMenu(!menu);
    }

    return (
        <Navbar 
            menuColor={menuColor} 
            textColor={textColor}
            btnColor={btnColor}
        >
            <span className="menu-btnOpen">
                <img
                    className={btnColor ?'btnChange' :''} 
                    src={menuOpen} 
                    alt="logo"
                    onClick={handleMenu} 
                />
            </span>
            <nav className={`nav-bar ${menu && 'nav-bar__active'}`}>
                <span className="menu-btnClose">
                    <img 
                        src={menuClose} 
                        alt="logo"
                        onClick={handleMenu} 
                    />
                </span>
                <img className='texture-left' src={bgUp} alt="decoration-left" />
                <ul className="sections-list">
                    {sections.map((section, key) => {
                        return(
                            !loading && (
                                <React.Fragment>
                                    <motion.li key={key} id={section.id}
                                        variants={fadeIn}
                                        initial="hidden"
                                        animate="visible"
                                        viewport={{once: true, amount: "some"}}
                                    >
                                        <Link 
                                            to={section.path}
                                            onClick={() => {handleMenu(); setTransition(true)}}
                                        >
                                            {section.name}
                                        </Link>
                                    </motion.li>
                                </React.Fragment>
                            )
                        );
                    })}
                </ul>
                <img className='texture-right' src={bgDown} alt="decoration-right" />
                <img className='logoWithBg' src={logoWithBg} alt="logo" />
            </nav>
        </Navbar>
    );
}

export default Header;