import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import logo from '../Assets/icons/Logo.svg';
import Header from '../components/Header';
import { AppContext } from '../context/AppContextProvider';
import '../styles/Home.css'; 
import { fadeIn, fadeInBottom } from '../styles/animations';

const container = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delay: 1,
        /* delayChildren: 1.8, */   /* Original 1.8 */
        staggerChildren: 0.5  /* Original 1 */
      }
    }
}

const Home = () => {
    const { loading } = useContext(AppContext);
    
    return (
        <React.Fragment>
            <Header menuColor={"transparent"} textColor={"#a5d548"} btnColor={false} />
            {/* {transition ? <Transition background={"#a5d548"}/> : ''} */}
            {
                    !loading && (
                        <React.Fragment>
                            <motion.section className="home"
                                variants={fadeIn}
                                initial="hidden"
                                whileInView="visible"
                                viewport={{once: true, amount: 'some'}}
                            >
                                <div className="home-container">
                                    <motion.div className={`logo`}
                                        custom={0.8}
                                        variants={fadeIn}
                                        initial="hidden"
                                        whileInView="visible"
                                        viewport={{once: true, amount: 'some'}}
                                    >
                                        <img src={logo} alt="Logo" />
                                    </motion.div>
                                    <motion.div className={`title`}
                                        variants={container}
                                        initial="hidden"
                                        whileInView="visible"
                                        viewport={{once: true, amount: 'some'}}
                                    >
                                        <motion.h1 className="name"
                                            custom={1}
                                            variants={fadeInBottom}
                                            initial="hidden"
                                            whileInView="visible"
                                            viewport={{once: true, amount: 'some'}}
                                        >
                                            Lic. Kimberly Jaén
                                        </motion.h1>
                                        <motion.p className="profession"
                                            custom={1.2}
                                            variants={fadeInBottom}
                                            initial="hidden"
                                            whileInView="visible"
                                            viewport={{once: true, amount: 'some'}}
                                        >
                                            Nutricionista y Dietista
                                        </motion.p>
                                    </motion.div>
                                </div>   
                            </motion.section>
                        </React.Fragment>
                    )
                }
        </React.Fragment>
    );
}

export default Home;