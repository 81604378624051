import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import brushLine from '../Assets/icons/brush-line.svg';
import brushCircle from '../Assets/icons/brush-circle.svg'; 
import { AppContext } from '../context/AppContextProvider';
import { fadeInRight } from '../styles/animations';
import { fadeIn } from '../styles/animations';

const Vision = () => {
    const { loading } = useContext(AppContext);

    return (
        <div className="vision">
            {
                !loading && (
                    <React.Fragment>
                        <motion.h1 className={`vision-title`}
                            variants={fadeInRight}
                            initial="hidden"
                            whileInView="visible"
                            viewport={{once: true, amount: 'some'}}
                        >
                            Visión
                        </motion.h1>
                        <motion.p className={`vision-description`}
                            custom={0.5}
                            variants={fadeIn}
                            initial="hidden"
                            whileInView="visible"
                            viewport={{once: true, amount: 'some'}}
                        >
                            Guiar la alimentación a lo largo de la vida. <br /> 
                            Embarazada, madre lactante, alimentación complementaria desde los 6 meses de vida. <br /> 
                            Orientación de suplementos para alcanzar objetivos nutricionales en paciente. <br /> 
                            Creación de menús saludables para reducir medidas y crear objetivos a corto, mediano y largo plazo. <br /> 
                        </motion.p>
                    </React.Fragment>
                )
            }
            <img src={brushLine} alt="brush line" className="brush-line" />
            <img src={brushCircle} alt="brush circle" className="brush-circle" />
        </div>
    );
}

export default Vision;