import React from 'react';
import '../styles/About.css';
import Header from '../components/Header';
import Biografia from '../components/Biografia';
import Vision from '../components/Vision';
import Mision from '../components/Mision';

const SobreMi = () => {
    return (
        <React.Fragment>
            <Header menuColor={"#ff2180"} textColor={"white"} btnColor={true}/>
            <div className="about">
                <Biografia />
                <Vision />
                <Mision />
            </div>
        </React.Fragment>
    );
}

export default SobreMi;

/* {dataAboutMe.map((info, key) => {
                return(
                    <div key={key} className={info.class}>
                        <h1 className={info.classTitle}>
                            {info.title}
                        </h1>
                        <p className={info.classDescription}>
                            {info.description}
                        </p>
                    </div>
                );
    })} */