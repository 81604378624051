import React, { useState } from 'react';

const AppContext = React.createContext();

const AppContextProvider = ({children}) => {
    const [menu, setMenu] = useState(false);
    const [loading, setLoading] = useState(true);
    const [transition, setTransition] = useState(false);
    return (
        <AppContext.Provider value={{
            menu,
            setMenu,
            loading,
            setLoading,
            transition, 
            setTransition
        }}>
            {children}
        </AppContext.Provider>
    );
}

export {AppContextProvider, AppContext};